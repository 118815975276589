// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC84RlGBB9OwkcTwdVakAJa7aVBLeNVG-0",
  authDomain: "casadamoda-1f011.firebaseapp.com",
  projectId: "casadamoda-1f011",
  storageBucket: "casadamoda-1f011.appspot.com",
  messagingSenderId: "689930120333",
  appId: "1:689930120333:web:9191e2195969ccdc7206c4",
  measurementId: "G-4CY2BSGB6F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
