import React from "react";
import "./Rodape.css";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaInstagram,
  FaWhatsapp,
  FaLock,
} from "react-icons/fa";
import { SiPix } from "react-icons/si";
import { MdLocalShipping } from "react-icons/md";
import correios from "./IMG/correios.png";
import ssl from "./IMG/ssl.png";
import logo from "./IMG/logorodape.png";

const Rodape = () => {
  return (
    <footer className="rodape">
      <div className="rodape-pagamento-seguranca-envio">
        <div className="rodape-pagamento">
          <h3>Formas de Pagamento</h3>
          <div className="bandeiras-pagamento">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcAmex />
            <FaCcDiscover />
            <SiPix className="pix-icon" />
          </div>
        </div>

        <div className="rodape-envio">
          <h3>Formas de Envio</h3>
          <div className="bandeiras-envio">
            <MdLocalShipping className="shipping-icon" />
            <img src={correios} alt="Correios" className="correios-logo" />
          </div>
        </div>

        
      <div className="rodape-social">
        <h3>UA Social</h3>
        <div className="social-icons">
          <a
            href="https://wa.me/5538998652694"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social-icon" />
            <span>WhatsApp</span>
          </a>
          <a
            href="https://www.instagram.com/casadamodamoc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
            <span>Instagram</span>
          </a>
        </div>
      </div>


      <div className="rodape-contato">
        <h3>Contato</h3>
        <p>
          <strong>E-mail:</strong> casadamodamoc@gmail.com
        </p>
        <p>
        <strong>Telefone:</strong> (38) 99136-0110
        </p>
        
      </div>

      </div>

      <div className="rodape-departamentos">
      <img src={logo} alt="Logo" className="rodape-logo" />
        <ul>
        <p>Moda e Vestuário</p>
      
        </ul>
      </div>

      <div className="rodape-seguranca">
          <h3>Segurança</h3>
          <div className="seguranca-icones">
            <FaLock className="shipping-icon" />
            <img src={ssl} alt="SSL" className="ssl-icon" />
          </div>
        </div>


     


        <div className="rodape-participe">
        <h3>Participe</h3>
        <p>
          <a href="https://www.instagram.com/casadamodamoc/" target="_blank" rel="noopener noreferrer">
            Grupo VIP Instagram
          </a>
        </p>
      </div>


    

      <div className="rodape-info">
        <h3> CODE-X</h3>
        <p>2024 Casa Da Moda - Todos os direitos reservados.</p>
        <p>© 2024 CodeX - Especialista em Lojas Virtuais</p>
      </div>
    </footer>
  );
};

export default Rodape;
